import React from "react";
import SbEditable from "storyblok-react";
import { getInputWidth } from "../helpers/utils";

const Input = (props) => {
   return (
      <SbEditable content={props.blok}>
         <div className={`input-group ${getInputWidth(props.blok)} `}>
            <label htmlFor={props.blok.name}>
               {props.blok.label}
               {props.blok.required && "*"}
            </label>

            <span className="block relative">
               <input id={props.blok.name} name={props.blok.name} type={props.blok.type} className={`rounded-none form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150 focus:shadow-outline-orange hover:shadow-outline-orange focus:border-orange-200 hover:border-orange-200 ${props.hasError ? "hover:shadow-outline-red focus:shadow-outline-red shadow-outline-red" : ""}`} placeholder={props.blok.placeholder || props.blok.label} required={props.blok.required} value={props.value} onChange={props.handleChange} />

               {props.hasError && (
                  <svg fill="currentColor" viewBox="0 0 20 20" data-element="error-icon" className="absolute right-0 h-6 w-6 mr-3 pointer-events-none top-1/2 transform transition duration-300 ease-in-out -translate-y-1/2 text-red-500 opacity-100 translate-x-0">
                     <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                  </svg>
               )}
            </span>
            {props.hasError && (
               <p className="mt-2 text-sm text-red-500" data-element="error-label">
                  Ce champ est incorrect.
               </p>
            )}
         </div>
      </SbEditable>
   );
};

export default Input;
