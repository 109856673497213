import React from "react";
import SbEditable from "storyblok-react";

const getAcronym = (str) => {
   return str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
      .toUpperCase();
};

const SectionTestimonials = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <section className="py-20 xl:bg-contain bg-top bg-no-repeat mt-0 -mx-4 md:-mx-6 intersection-background" data-width="full-width">
            <div className="container px-4 mx-auto">
               <div className="max-w-lg mx-auto mb-12 text-center">
                  <svg className="mx-auto text-orange-500" width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M16.6153 19.154H10.1537C9.38437 19.154 8.73037 18.8849 8.19185 18.3463C7.65363 17.8078 7.38417 17.154 7.38417 16.3845V15.4619C7.38417 13.4233 8.10546 11.6831 9.54795 10.2406C10.9903 8.79859 12.7309 8.0773 14.7693 8.0773H16.6153C17.1152 8.0773 17.5477 7.89453 17.9133 7.52929C18.2786 7.16384 18.4613 6.73131 18.4613 6.23128V2.53864C18.4613 2.03872 18.2785 1.60578 17.9133 1.24034C17.5478 0.875398 17.1153 0.692322 16.6153 0.692322H14.7693C12.7691 0.692322 10.8608 1.08212 9.04327 1.86059C7.22595 2.63958 5.65404 3.69257 4.32694 5.01967C2.99994 6.34616 1.94726 7.91817 1.16837 9.7357C0.389491 11.553 0 13.4618 0 15.4618V35.769C0 37.3083 0.538216 38.6152 1.61515 39.6926C2.69219 40.7693 4.00019 41.3076 5.53856 41.3076H16.616C18.1542 41.3076 19.4618 40.7693 20.539 39.6926C21.6157 38.6152 22.1542 37.3083 22.1542 35.769V24.6926C22.1542 23.1536 21.6157 21.8466 20.5383 20.7692C19.4616 19.6925 18.1535 19.154 16.6153 19.154Z" fill="currentColor" />
                     <path d="M46.3856 20.7692C45.309 19.6925 44.0013 19.154 42.4626 19.154H36.0011C35.2322 19.154 34.5776 18.8849 34.04 18.3463C33.5012 17.8078 33.2323 17.154 33.2323 16.3845V15.4619C33.2323 13.4233 33.9536 11.6831 35.3954 10.2406C36.8372 8.79859 38.5777 8.0773 40.6171 8.0773H42.4627C42.9627 8.0773 43.3955 7.89453 43.7608 7.52929C44.1258 7.16384 44.3092 6.73131 44.3092 6.23128V2.53864C44.3092 2.03872 44.1259 1.60578 43.7608 1.24034C43.3956 0.875398 42.9628 0.692322 42.4627 0.692322H40.6171C38.6158 0.692322 36.7079 1.08212 34.8899 1.86059C33.0729 2.63958 31.5015 3.69257 30.1744 5.01967C28.8473 6.34616 27.7941 7.91817 27.0155 9.7357C26.2368 11.553 25.8468 13.4618 25.8468 15.4618V35.769C25.8468 37.3083 26.3855 38.6152 27.4622 39.6926C28.5389 40.7693 29.8466 41.3076 31.3852 41.3076H42.462C44.0006 41.3076 45.3082 40.7693 46.3849 39.6926C47.4623 38.6152 47.9999 37.3083 47.9999 35.769V24.6926C48 23.1535 47.4623 21.8466 46.3856 20.7692Z" fill="currentColor" />
                  </svg>

                  <h2 className="my-2 text-3xl md:text-4xl font-bold" dangerouslySetInnerHTML={{ __html: blok.title }} />
                  <p className="text-blueGray-400 leading-loose">{blok.description}</p>
               </div>
               <div className="flex flex-wrap -mx-3 mb-6 items-stretch">
                  {blok.testimonials.map((testimonial) => (
                     <SbEditable content={testimonial}>
                        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6" key={testimonial._uid}>
                           <div className="p-8 bg-white shadow rounded h-full">
                              <div className="flex items-center mb-4">
                                 <div className="hidden md:flex md:h-16 md:w-16 rounded-full overflow-hidden bg-orange-500 items-center justify-center flex-shrink-0">{testimonial.picture.filename ? <img className="w-full h-full object-cover" src="https://images.unsplash.com/photo-1556474835-b0f3ac40d4d1?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60" alt="" /> : <span className="font-bold text-white text-xl">{getAcronym(testimonial.name)}</span>}</div>

                                 <div className="md:pl-4">
                                    <p className="text-xl">{testimonial.name}</p>
                                    {testimonial.title && <p className="text-orange-500">{testimonial.title}</p>}
                                 </div>
                              </div>
                              <p className="text-blueGray-600 leading-normal">{testimonial.content}</p>
                           </div>
                        </div>
                     </SbEditable>
                  ))}
               </div>
               <div className="text-center">
                  {blok.link.url && (
                     <a className="inline-block py-4 px-8 text-white font-semibold leading-none bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 transition duration-300 ease rounded" href={blok.link.url} target="_blank" rel="nooppener noreferrer">
                        {blok.link_title}
                     </a>
                  )}
               </div>
            </div>
         </section>
      </SbEditable>
   );
};

export default SectionTestimonials;
