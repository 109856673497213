import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponents } from "../../../hooks/components";

const getVerticalAlign = (value) => {
   switch (value) {
      case "center":
         return "lg:items-center";
      case "bottom":
         return "lg:items-end";
      default:
         return "lg:items-start";
   }
};

const ContentTwoColumns = ({ blok }) => {
   console.log(blok);
   return (
      <SbEditable content={blok}>
         <div className="bg-white overflow-hidden">
            <div className="relative md:bg-white">
               <div className={`flex ${blok.settings_reverse ? "flex-col-reverse" : "flex-col"} lg:grid lg:grid-cols-2 lg:gap-10 ${getVerticalAlign(blok.settings_v_align)}`}>
                  <div className="lg:max-w-none lg:mb-0 space-y-6">{blok.left_column && <RenderComponents bloks={blok.left_column} />}</div>
                  <div className={`space-y-6 ${blok.settings_reverse ? "mb-6 lg:mb-0" : "mt-6 lg:mt-0"}`}>{blok.right_column && <RenderComponents bloks={blok.right_column} />}</div>
               </div>
            </div>
         </div>
      </SbEditable>
   );
};

export default ContentTwoColumns;
