import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponents } from "../../../hooks/components";

const ContentCentered = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <div className="bg-white px-4 sm:px-6 lg:px-8 mb-8">
            <div className="max-w-prose mx-auto space-y-12 md:space-y-16">{blok.content && <RenderComponents bloks={blok.content} />}</div>
         </div>
      </SbEditable>
   );
};

export default ContentCentered;
