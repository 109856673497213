import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import gsap from "gsap";

import Components from "./components";

const Navi = ({ location }) => {
   const [siteInfos, setSiteInfos] = useState({});
   const header = useRef(null);

   const siteData = useStaticQuery(graphql`
      {
         storyblokSpace {
            name
         }
         storyblokEntry(slug: { eq: "global-navi" }) {
            id
            content
         }
      }
   `);

   const blok = JSON.parse(siteData.storyblokEntry.content);

   useEffect(() => {
      setSiteInfos({
         title: blok.site_informations_title || siteData.storyblokSpace.name,
         logo: blok.site_informations_logo || null,
      });
   }, [siteData]);

   const openMenu = () => {
      let delay = 0.3;

      gsap.to(header.current.querySelector('[data-element="menu"]'), { display: "block", translateX: 0, duration: 0.3 });
      gsap.to(header.current.querySelector('[data-element="menu-overlay"]'), { display: "block", opacity: 1, duration: 0.3 });
      gsap.to(header.current.querySelector('[data-element="brand"]'), { opacity: 1, translateX: 0, duration: 0.3, delay });
   };

   const closeMenu = () => {
      gsap.to(header.current.querySelector('[data-element="menu"]'), { display: "none", translateX: "-100%", duration: 0.3 });
      gsap.to(header.current.querySelector('[data-element="menu-overlay"]'), { display: "none", opacity: 0, duration: 0.3 });
      gsap.to(header.current.querySelector('[data-element="brand"]'), { opacity: 0, translateX: -20, duration: 0.3, delay: 0.3 });
   };

   return (
      <>
         <div className="relative bg-black px-4" ref={header}>
            <div className="relative max-w-7xl mx-auto z-996">
               <div className="flex justify-between items-center py-4 2md:py-6 2md:justify-start 2md:space-x-10">
                  <div className="w-0 flex-1 flex">
                     <Link to="/" className="inline-flex">
                        {siteInfos.logo ? <img className="h-8 w-auto sm:h-10" width="158" height="40" src={siteInfos.logo} alt={siteInfos.title} /> : <p>{siteInfos.title}</p>}
                     </Link>
                  </div>
                  <div className="-mr-2 -my-2 2md:hidden">
                     <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-gray-100 hover:bg-opacity-25 focus:outline-none focus:bg-gray-100 focus:bg-opacity-25 transition duration-150 ease-in-out" onClick={() => openMenu()}>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <span className="ml-1 mt-px text-white font-bold">MENU</span>
                     </button>
                  </div>
                  <nav className="fixed hidden 2md:relative 2md:flex 2md:space-x-10 top-0 left-0 right-12 bottom-0 2md:top-0 2md:right-auto 2md:left-auto 2md:bottom-auto bg-white rounded-tr rounded-br shadow-lg p-3 2md:bg-transparent 2md:rounded-none 2md:shadow-none 2md:p-0 z-50 transform origin-top-left -translate-x-full 2md:scale-100 2md:opacity-100 2md:translate-x-0 max-w-sm 2md:max-w-none" data-element="menu">
                     <div className="flex justify-between items-center mb-4 2md:hidden">
                        {/* {siteInfos.logo ? <img className="h-8 w-auto sm:h-10 xs:w-48 2md:opacity-1 transform" width="158" height="40" src={siteInfos.logo} alt={siteInfos.title} data-element="brand" /> : <p data-element="brand">{siteInfos.title}</p>} */}

                        <button type="button" className="ml-auto inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" onClick={() => closeMenu()}>
                           <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-6 w-6">
                              <path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path>
                           </svg>{" "}
                           <span className="block ml-1 text-gray-900 font-bold">Fermer</span>
                        </button>
                     </div>

                     <div className="flex flex-col 2md:flex-row space-y-3 2md:space-y-0 2md:items-center">
                        <ul className="space-y-3 2md:space-y-0 p-0 m-0 2md:flex 2md:space-x-8 items-center">{blok.main_nav && blok.main_nav.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: location }))}</ul>
                     </div>
                  </nav>

                  <span data-element="menu-overlay" className="fixed hidden 2md:hidden opacity-0 inset-0 z-40 bg-black bg-opacity-50" onClick={() => closeMenu()}></span>
               </div>
            </div>
         </div>
      </>
   );
};

export default Navi;
