import React, { useRef, useEffect, useState } from "react";

const BlockEcwid = ({ blok }) => {
   const BoutiqueView = useRef(null);
   const blockTitle = useRef(null);

   const [boutiqueLoaded, setBoutiqueLoaded] = useState(false);
   const [displayTitle, setDisplayTitle] = useState(true);
   const [apiLoaded, setApiLoaded] = useState(false);
   const [awaitEcwid, setAwaitEcwid] = useState(false);
   const [ecwidEventReady, setEcwidEventReady] = useState(false);

   const handler = () => {
      window.ecwid_script_defer = true;
      window.ecwid_dynamic_widgets = true;

      if (typeof window.Ecwid != "undefined") window.Ecwid.destroy();

      window._xnext_initialization_scripts = [
         {
            widgetType: "ProductBrowser",
            id: `my-store-${blok.shop_id}`,
            arg: ["itemsPerPage=12"],
         },
      ];

      if (!document.getElementById("ecwid-script")) {
         var script = document.createElement("script");
         script.type = "text/javascript";
         script.src = `https://app.ecwid.com/script.js?${blok.shop_id}`;
         script.id = "ecwid-script";
         document.body.appendChild(script);
      } else {
         window.ecwid_onBodyDone();
      }

      setAwaitEcwid(
         setInterval(() => {
            if (typeof window.Ecwid !== undefined) {
               setApiLoaded(window.Ecwid);
            } else {
               console.log("awaiting Ecwid");
            }
         }, 1000)
      );
   };

   useEffect(() => {
      if (apiLoaded) {
         clearInterval(awaitEcwid);

         if (!ecwidEventReady) initEcwidEvent();
      }

      return () => {
         clearInterval(awaitEcwid);
         setEcwidEventReady(false);
      };
   }, [apiLoaded]);

   useEffect(() => {
      // handler()
      if (BoutiqueView.current && !boutiqueLoaded) {
         handler();
         setBoutiqueLoaded(true);
      }

      return () => {
         setBoutiqueLoaded(false);
      };
   }, [BoutiqueView]);

   const initEcwidEvent = () => {
      setEcwidEventReady(true);

      apiLoaded.OnPageLoaded.add((page) => {
         if (page.type === "CATEGORY" && page.categoryId === 0) setDisplayTitle(true);
         else setDisplayTitle(false);
      });
   };

   return (
      <div data-width="full-width" className="mt-10">
         <div className="max-w-7xl mx-auto">
            {displayTitle && (
               <h1 ref={blockTitle} className="text-4xl font-bold">
                  {blok.block_title}
               </h1>
            )}

            {!apiLoaded && (
               <div id="mk-ecwid-loader" className="mx-auto text-center my-12">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="inline-block animate-spin svg-inline--fa fa-spinner-third fa-w-16" data-icon="spinner-third" data-prefix="fad" viewBox="0 0 512 512" width="60" height="60">
                     <g className="fa-group">
                        <path fill="currentColor" d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 01-6.94-19.2A184 184 0 11256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0115.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z" className="fa-secondary" opacity="0.4"></path>
                        <path fill="currentColor" d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 01-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 00440 256c0-96.49-74.27-175.63-168.77-183.38z" className="fa-primary"></path>
                     </g>
                  </svg>
               </div>
            )}

            <div className="-mx-2 sm:-mx-3">
               <div id={`my-store-${blok.shop_id}`} ref={BoutiqueView} className="-mx-3"></div>
            </div>
         </div>
      </div>
   );
};

export default BlockEcwid;
