import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const Loader = (props) => {
   const loaderWrapper = useRef(null);
   const iconLoading = useRef(null);
   const iconSuccess = useRef(null);
   const iconError = useRef(null);

   useEffect(() => {
      if (loaderWrapper) {
         if (props.mandrillState === "waiting") gsap.to(loaderWrapper.current, { display: "none", opacity: 0, duration: 0.3 });
         else {
            gsap.to(loaderWrapper.current, { display: "flex", opacity: 1, duration: 0.3 });

            if (props.mandrillState === "success") {
               gsap.to(iconLoading.current, { opacity: 0, display: "none", duration: 0.3 });
               gsap.to(iconSuccess.current, { opacity: 1, display: "block", duration: 0.3, delay: 0 });
            } else if (props.mandrillState === "error") {
               gsap.to(iconLoading.current, { opacity: 0, display: "none", duration: 0.3 });
               gsap.to(iconError.current, { opacity: 1, display: "block", duration: 0.3, delay: 0 });
            }
         }
      }
   });

   const getColor = () => {
      switch (props.mandrillState) {
         case "success":
            return "bg-green-500 text-white";
         case "error":
            return "bg-red-500 text-white";
         default:
            return "bg-gray-100 text-black";
      }
   };

   return (
      <div className="hidden opacity-0 flex items-center justify-center absolute inset-0 z-40 bg-white bg-opacity-75" ref={loaderWrapper}>
         <div className={`flex items-center justify-center w-52 h-52 rounded shadow-lg transition duration-300 ease-in-out ${getColor()}`}>
            <svg aria-hidden="true" focusable="false" className="flex-shrin-0 block h-24 w-24 z-50 animate-spin transition duration-300 ease-in-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ref={iconLoading}>
               <path fill="currentColor" d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"></path>
            </svg>

            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="check-circle" className="hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-shrin-0 block h-24 w-24 z-50 transition duration-300 ease-in-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ref={iconSuccess}>
               <path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"></path>
            </svg>

            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="exclamation-circle" className="hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-shrin-0 block h-24 w-24 z-50 transition duration-300 ease-in-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ref={iconError}>
               <path fill="currentColor" d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"></path>
            </svg>
         </div>
      </div>
   );
};

export default Loader;
