import React from "react";
import Components from "../components/components";
import FormsComponents from "../components/forms";

export const RenderComponent = ({ blok, extraClass = false }) => {
   return blok ? React.createElement(Components(blok.component || blok.type), { key: blok._uid, blok: blok, extraClass: extraClass }) : <p className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800">Sauvegarder pour afficher le bloc</p>;
};

export const RenderComponents = ({ bloks, extraClass = false }) => {
   if (bloks)
      return bloks.map((blok, i) => {
         return <RenderComponent blok={blok} key={blok._uuid || i} extraClass={extraClass} />;
      });
   else return false;
};

export const RenderForm = ({ blok }) => {
   return React.createElement(FormsComponents(blok.component || blok.type), { key: blok._uid, blok: blok });
};

export const RenderForms = ({ bloks }) => {
   if (bloks)
      return bloks.map((blok, i) => {
         return <RenderForm blok={blok} key={blok._uuid || i} />;
      });
   else return false;
};
