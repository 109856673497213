import React from "react";
import SbEditable from "storyblok-react";
import { getInputWidth } from "../helpers/utils";

const Radio = (props) => {
   return (
      <SbEditable content={props.blok}>
         <div className={`input-group relative sm:col-span-2 ${getInputWidth(props.blok)}`}>
            <p className="flex items-center space-x-2">{props.blok.label}</p>
            <div className="space-y-2">
               {props.blok.options.split("\n").map((option) => {
                  return (
                     <label key={`${option}-${props.blok._uid}`} className="flex items-center">
                        <input type="radio" name={props.blok.name} id={props.blok.name} value={option} className={`form-radio focus:ring-orange-500 h-4 w-4 border-gray-300 ${props.hasError && "shadow-outline-red hover:shadow-outline-red focus:shadow-outline-red"}`} checked={props.value === option} onChange={props.handleChange} />
                        <span className="ml-2 block">{option}</span>
                     </label>
                  );
               })}
            </div>
         </div>
      </SbEditable>
   );
};

export default Radio;
