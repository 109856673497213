const purgeWhitelistPatterns = [];
["red", "orange", "yellow", "green", "blue", "gray"].forEach((color) => {
   purgeWhitelistPatterns.push(new RegExp(`^(?!divide|placeholder)[a-zA-Z:]*-${color}-[0-9]*`));
});

purgeWhitelistPatterns.push(new RegExp(`^(?!divide|placeholder)[a-zA-Z:]*-white*`));

module.exports = {
   future: {
      removeDeprecatedGapUtilities: true,
      purgeLayersByDefault: true,
   },
   purge: {
      layers: ["components", "utilities"],
      content: ["./src/**/*.js"],
      options: {
         whitelistPatterns: purgeWhitelistPatterns,
      },
   },
   theme: {
      screens: {
         sm: "640px",
         md: "768px",
         "2md": "850px",
         lg: "1024px",
         xl: "1280px",
         "2xl": "1536px",
      },

      fontFamily: {
         sans: ["Lato"],
         heading: ["Limelight"],
      },
      extend: {
         colors: {
            green: {
               100: "#a5ffa2",
               200: "#87ea86",
               300: "#69cc6b",
               400: "#4baf50",
               500: "#74AC2E",
               600: "#00771b",
               700: "#005c00",
               800: "#004200",
               900: "#002b00",
            },
            blue: {
               100: "#acdcff",
               200: "#8ebfff",
               300: "#70a3eb",
               400: "#5087cd",
               500: "#2e6db0",
               600: "#005494",
               700: "#003c78",
               800: "#00265e",
               900: "#001045",
            },
            yellow: {
               100: "#ffea39",
               200: "#ffcd03",
               300: "#deb000",
               400: "#be9500",
               500: "#9e7a00",
               600: "#806000",
               700: "#654700",
               800: "#4e3000",
               900: "#3c1800",
            },
            orange: {
               100: "#ffbc81",
               200: "#ff9e66",
               300: "#ff804b",
               400: "#ff6231",
               500: "#e74415",
               600: "#a42300",
               700: "#a30000",
               800: "#840000",
               900: "#660000",
            },
            red: {
               100: "#ffa491",
               200: "#ff8676",
               300: "#ff685c",
               400: "#f44843",
               500: "#d2252b",
               600: "#b00014",
               700: "#900000",
               800: "#700000",
               900: "#540000",
            },
            blueGray: {
               50: "#f8fafc",
               100: "#f1f5f9",
               200: "#e2e8f0",
               300: "#cbd5e1",
               400: "#94a3b8",
               500: "#64748b",
               600: "#475569",
               700: "#334155",
               800: "#1e293b",
               900: "#0f172a",
            },
            brown: {
               100: "#ffb472",
               200: "#ff9657",
               300: "#ff7a3c",
               400: "#e75d21",
               500: "#c54100",
               600: "#a42300",
               700: "#840000",
               800: "#670000",
               900: "#4e0000",
            },
            black: "#222121",
         },
         whitespace: {
            "break-spaces": "break-spaces",
         },
         height: {
            "px-380": "380px",
            auto: "auto",
         },
         width: {
            auto: "auto",
         },
         minHeight: {
            "px-380": "380px",
         },
         maxWidth: {
            "21ch": "21ch",
            "px-808": "808px",
            "px-950": "950px",
         },
         spacing: {
            "16/9": "56.25%",
         },
         scale: {
            "i-100": "-1",
         },
         zIndex: {
            996: 996,
            997: 997,
            998: 998,
            999: 999,
            1000: 1000,
         },
         transitionProperty: {
            spacing: "margin, padding",
         },
         borderRadius: {
            "5xl": "100px",
         },
      },
      mk: {
         colors: {
            mainKey: {
               green: 400,
               blue: 500,
               yellow: 200,
               orange: 500,
               brown: 500,
               red: 500,
            },
         },
      },
      typography: {
         default: {
            css: {
               a: {
                  color: null,
               },
            },
         },
      },
   },
   variants: {
      width: ["responsive", "hover", "focus"],
      height: ["responsive", "hover", "focus"],
      padding: ["responsive", "hover", "focus"],
      inset: ["responsive", "hover", "focus"],
   },
   plugins: [require("@tailwindcss/ui")],
};
