import React from "react";
import SbEditable from "storyblok-react";
import { getInputWidth } from "../helpers/utils";

const Select = (props) => {
   return (
      <SbEditable content={props.blok}>
         <div className={`input-group relative ${getInputWidth(props.blok)} `}>
            <label htmlFor={props.blok.name}>
               {props.blok.label}
               {props.blok.required && "*"}
            </label>
            <span className="block relative">
               <select name={props.blok.name} id={props.blok.name} className="form-select rounded-none w-full py-3 px-4" aria-required="true" value={props.value} onChange={props.handleChange}>
                  {props.blok.options.split("\n").map((option) => {
                     return (
                        <option value={option} key={`${props.blok._uid}-${option}`}>
                           {option}
                        </option>
                     );
                  })}
               </select>
            </span>
         </div>
      </SbEditable>
   );
};

export default Select;
