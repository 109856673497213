import React from "react";
import Paragraph from "./paragraph";

const UnorderedList = ({ blok }) => {
   let listItem = [];
   blok.content.forEach((item) => {
      item.content.forEach((contentItem) => {
         listItem.push(contentItem);
      });
   });

   return (
      blok.content && (
         <ul>
            {listItem.map((item, i) => {
               return (
                  <li key={`list-item-${i}`}>
                     <Paragraph blok={item} extraClasses={"mt-0"} />
                  </li>
               );
            })}
         </ul>
      )
   );
};

export default UnorderedList;
