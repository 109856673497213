import React, { useState } from "react";
import SbEditable from "storyblok-react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { isEmail } from "../../../plugins/forms/helpers/validation";
import { Link } from "gatsby";

const url = "https://factorylescygnes.us11.list-manage.com/subscribe/post?u=0ce22e87d7a5c8e6c026356ae&amp;id=c9fb29599b";

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
   const [emailValue, setEmailValue] = useState("");
   const [honeypotValue, setHoneypotValue] = useState("");
   const [RGPDValue, setRGPDValue] = useState(false);
   const [invalidEmail, setInvalidEmail] = useState(false);
   const [invalidRGPD, setInvalidRGPD] = useState(false);

   const submit = () =>
      onValidated({
         EMAIL: emailValue,
      });

   const handleFormSubmit = (e) => {
      e.preventDefault();

      let hasError = false;

      // On vérifie l'email
      if (!emailValue || !isEmail(emailValue)) {
         hasError = true;
         setInvalidEmail(true);
      } else {
         setInvalidEmail(false);
      }
      // On vérifie honeypot
      if (honeypotValue.length > 0) hasError = true;

      // On vérifie les CGU
      if (!RGPDValue) {
         hasError = true;
         setInvalidRGPD(true);
      } else {
         setInvalidRGPD(false);
      }

      if (!hasError) {
         submit();
      }
   };

   return (
      <div>
         {status === "error" && <div className="max-w-lg mx-auto block py-1 px-3 text-sm font-semibold  bg-red-100  text-red-800 rounded mb-3" dangerouslySetInnerHTML={{ __html: message }} />}
         {status === "success" && <div className="max-w-lg mx-auto block py-1 px-3 text-sm font-semibold  bg-green-100  text-green-800 rounded mb-3">Merci pour votre inscription !</div>}

         {status !== "success" && (
            <div className="flex flex-col md:flex-row flex-wrap space-y-4 md:space-y-0 max-w-lg mx-auto text-left">
               <div className={`relative flex-shrink-0 flex-grow md:order-1 md:mr-3`}>
                  <span className="block relative">
                     <svg className="absolute top-1/2 transform -translate-y-1/2 left-2 h-6 w-6 my-auto text-blueGray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                     </svg>

                     <input id={`mailchimp-email`} name={`mailchimp-email`} type={`email`} className={` form-input block w-full py-3 pl-9 pr-4 placeholder-gray-500 transition ease-in-out duration-150 focus:shadow-outline-orange hover:shadow-outline-orange rounded-none focus:border-orange-200 hover:border-orange-200 ${invalidEmail ? "hover:shadow-outline-red focus:shadow-outline-white shadow-outline-red" : ""}`} placeholder="Votre adresse e-mail" required={`required`} value={emailValue} onChange={(e) => setEmailValue(e.target.value)} />

                     {invalidEmail && (
                        <svg fill="currentColor" viewBox="0 0 20 20" data-element="error-icon" className="absolute right-0 h-6 w-6 mr-3 pointer-events-none top-1/2 transform transition duration-300 ease-in-out -translate-y-1/2 text-red-500 opacity-100 translate-x-0">
                           <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                        </svg>
                     )}
                  </span>
               </div>

               <input type="text" name="your-valid-attribute" className="opacity-0 absolute top-0 left-0 height-none width-none -z-10" tabIndex="-1" autoComplete="off" onChange={(e) => setHoneypotValue(e.target.value)} value={honeypotValue} />

               <div className={`input-group relative md:order-3 flex-grow-1`}>
                  <label className="flex items-center space-x-2 md:mt-3">
                     <input
                        type="checkbox"
                        name="mailchimp-rgpd"
                        id="mailchimp-rgpd"
                        value="RGPDMailchimp"
                        className={`form-checkbox hover:shadow-outline-orange transition duration-150 ease-in rounded-none text-orange-200 focus:border-orange-200 hover:border-orange-200 ${invalidRGPD && "shadow-outline-red hover:shadow-outline-red focus:shadow-outline-white"}`}
                        onClick={(e) => setRGPDValue(!RGPDValue)}
                        onChange={(e) => true}
                        onKeyPress={(e) => {
                           e.preventDefault();
                           setRGPDValue(!RGPDValue);
                        }}
                        checked={!!RGPDValue}
                        required={`required`}
                     />

                     <span className={`block leading-tight ${invalidRGPD ? "text-red-500" : "text-white"}`}>
                        En cochant cette case, vous acceptez notre <Link to="/politique-de-confidentialite/">politique de confidentialité</Link>
                     </span>
                  </label>
               </div>

               <button className={`relative w-full md:w-auto py-4 px-8 ${status === "sending" && "pr-10 pl-6"} text-black font-semibold leading-none bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 transition-all ease duration-300 md:order-2`} type="submit" disabled={status === "sending"} onClick={(e) => handleFormSubmit(e)}>
                  <span>Je m'inscris</span>

                  {status === "sending" && (
                     <span className="absolute top-1/2 transform -translate-y-1/2 right-2">
                        <svg aria-hidden="true" data-prefix="far" data-icon="spinner-third" className="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                           <defs />
                           <path fill="currentColor" d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                        </svg>
                     </span>
                  )}
               </button>
            </div>
         )}
      </div>
   );
};

const SectionNewsletter = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <section className="py-20 bg-black -mx-4 md:-mx-6" data-block="section-newsletter" data-width="full-width">
            <div className="container px-4 mx-auto">
               <div className="max-w-xl mx-auto text-center">
                  <h2 className="mb-4 text-3xl lg:text-4xl text-white font-medium leading-tight">{blok.title}</h2>

                  {blok.description && <p className="mb-8 text-blueGray-400">{blok.description}</p>}

                  <MailchimpSubscribe url={url} render={({ subscribe, status, message }) => <CustomForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />} />
               </div>
            </div>
         </section>
      </SbEditable>
   );
};

export default SectionNewsletter;
