import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponents } from "../../../hooks/components";
import FluidImage from "../../atoms/images/fluidImage";

const HeroStore = ({ blok }) => (
   <SbEditable content={blok}>
      <section className="py-12 md:pt-24 md:pb-0 -px-4 md:-px:6" data-width="full-width">
         <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center lg:flex-nowrap">
               <div className="w-full lg:w-1/2">
                  <div className="py-6 lg:pr-32">
                     <div className="mb-4">
                        <h1 className="text-4xl mt-3">{blok.title}</h1>
                     </div>

                     {blok.address.content && blok.address.content.length > 0 && (
                        <div className="flex items-start py-4">
                           <div className="w-8 mr-5 text-brown-500">
                              <svg className="w-8 h-8" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marked-alt" className="svg-inline--fa fa-map-marked-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                 <path fill="currentColor" d="M344 126c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 13.2 10.7 24 24 24s24-10.8 24-24zm-24 226c5 0 10-2 13.5-6.1 35.3-40 127.3-150.1 127.3-210.6C460.8 60.6 397.8 0 320 0S179.2 60.6 179.2 135.3c0 60.4 92 170.6 127.3 210.6C310 350 315 352 320 352zm0-304c51.2 0 92.8 39.2 92.8 87.3 0 21.4-31.8 79.1-92.8 152.6-61-73.5-92.8-131.2-92.8-152.6 0-48.1 41.6-87.3 92.8-87.3zm240 112c-2 0-4 .4-6 1.2l-73.5 27.2c-8.2 20.4-20.2 42-34.2 63.8L528 222v193l-128 44.5V316.3c-13.7 17.3-27.9 34.3-42.5 50.8-1.7 1.9-3.6 3.5-5.5 5.1v81.4l-128-45.2v-113c-18.1-24.1-34.8-48.8-48-72.8v180.2l-.6.2L48 450V257l123.6-43c-8-15.4-14.1-30.3-18.3-44.5L20.1 216C8 220.8 0 232.6 0 245.7V496c0 9.2 7.5 16 16 16 2 0 4-.4 6-1.2L192 448l172 60.7c13 4.3 27 4.4 40 .2L555.9 456c12.2-4.9 20.1-16.6 20.1-29.7V176c0-9.2-7.5-16-16-16z"></path>
                              </svg>
                           </div>
                           <div>
                              <h3 className="mb-2 text-xl">Adresse</h3>
                              <div className="text-blueGray-500 leading-loose">
                                 <RenderComponents bloks={blok.address.content} />
                              </div>
                           </div>
                        </div>
                     )}

                     {blok.hours.content && blok.hours.content.length > 0 && (
                        <div className="flex items-start py-4">
                           <div className="w-8 mr-5 text-brown-500">
                              <svg className="w-8 h-8" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                 <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                              </svg>
                           </div>
                           <div>
                              <h3 className="mb-2 text-xl">Horaire</h3>
                              <div className="text-blueGray-500 leading-loose">
                                 <RenderComponents bloks={blok.hours.content} />
                              </div>
                           </div>
                        </div>
                     )}

                     {blok.phone.content && blok.phone.content.length > 0 && (
                        <div className="flex items-start py-4">
                           <div className="w-8 mr-5 text-brown-500">
                              <svg className="w-8 h-8" aria-hidden="true" focusable="false" data-prefix="far" data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                 <path fill="currentColor" d="M484.25 330l-101.59-43.55a45.86 45.86 0 0 0-53.39 13.1l-32.7 40a311.08 311.08 0 0 1-124.19-124.12l40-32.7a45.91 45.91 0 0 0 13.1-53.42L182 27.79a45.63 45.63 0 0 0-52.31-26.61L35.5 22.89A45.59 45.59 0 0 0 0 67.5C0 313.76 199.68 512.1 444.56 512a45.58 45.58 0 0 0 44.59-35.51l21.7-94.22a45.75 45.75 0 0 0-26.6-52.27zm-41.59 134.09C225.08 463.09 49 287 48 69.3l90.69-20.9 42.09 98.22-68.09 55.71c46.39 99 98.19 150.63 197 197l55.69-68.11 98.19 42.11z"></path>
                              </svg>
                           </div>
                           <div>
                              <h3 className="mb-2 text-xl">Téléphone</h3>
                              <div className="text-blueGray-500 leading-loose">
                                 <RenderComponents bloks={blok.phone.content} />
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
                  <FluidImage blok={blok.image} className="relative mx-auto rounded-tr-3xl rounded-bl-3xl sm:rounded-tr-5xl sm:rounded-bl-5xl w-full z-10" />
               </div>
            </div>
         </div>
      </section>
   </SbEditable>
);

export default HeroStore;
