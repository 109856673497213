import React from "react";
import SbEditable from "storyblok-react";
import Components from "../../components";

const Content = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <div className="prose">{blok.content && blok.content.content.map((childBlok, index) => React.createElement(Components(childBlok.type), { key: `childBlock-${index}`, blok: childBlok }))}</div>
      </SbEditable>
   );
};

export default Content;
