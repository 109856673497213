import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponent, RenderComponents } from "../../../hooks/components";

const SectionContact = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <section className="py-20 -px-4 md:-px:6 mt-0" data-width="full-width">
            <div className="container px-4 mx-auto">
               <div className="max-w-2xl mx-auto text-center">
                  <div className="max-w-md mb-8 mx-auto">
                     {blok.surtitle && <span className="text-sm text-blueGray-500">{blok.surtitle}</span>}
                     <h2 className="mt-2 text-4xl">{blok.title}</h2>
                     {blok.introduction?.content && blok.introduction.content.length > 0 && (
                        <div className="text-blueGray-500 leading-relaxed mx-auto space-y-4 mt-4">
                           <RenderComponents bloks={blok.introduction.content} />
                        </div>
                     )}
                  </div>
                  <div className="text-left">
                     <RenderComponent blok={blok.form.content} />
                  </div>
               </div>
            </div>
         </section>
      </SbEditable>
   );
};

export default SectionContact;
