import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Lightbox = ({ children, options }) => {
   // const options = {};

   return (
      <SimpleReactLightbox>
         <SRLWrapper>{children}</SRLWrapper>
      </SimpleReactLightbox>
   );
};

export default Lightbox;

export const LightboxItem = ({ image, children }) => {
   return (
      <a href={image} title="Afficher la galerie" className="block relative group">
         {children}

         <span className="absolute w-full h-full inset-0 flex items-center justify-center bg-gray-500 opacity-0 bg-opacity-50 group-hover:opacity-100 transition duration-300 ease-in pointer-events-none">
            <i className="block">
               <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
               </svg>
            </i>
         </span>
      </a>
   );
};
