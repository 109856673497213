export const isEmail = (value) => {
   return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
};

export const isURL = (value) => {
   /*eslint no-useless-escape: "ignore"*/
   return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(value);
};

export const validateInput = (input, value) => {
   if (input.required && !value) return false;

   if (value && input.type === "email" && !isEmail(value)) return false;

   if (value && input.type === "URL" && !isURL(value)) return false;

   if (input.name === "your-valid-attribute" && value) return false;

   return true;
};

export const validateCheckbox = (input, value) => {
   if (input.required && !value) return false;

   return true;
};
