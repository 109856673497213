export const getInputWidth = (input) => {
   switch (input.width) {
      case "small":
         return "sm:col-span-1 md:col-span-3";
      case "moyen":
         return "sm:col-span-2 md:col-span-6";
      case "large":
         return "sm:col-span-2 md:col-span-8";
      default:
         return "sm:col-span-2 md:col-span-12";
   }
};

const getFormattedDateTime = () => {
   let newDate = new Date();
   let day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
   let month = newDate.getMonth() < 10 ? `0${newDate.getMonth()}` : newDate.getMonth();
   let year = newDate.getFullYear();
   let hours = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
   let minute = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes();

   return `${day}/${month}/${year} - ${hours}:${minute}`;
};

export const parseTextByValues = (initialText, values) => {
   let text = initialText;

   Object.keys(values).forEach((key) => {
      text = text.replace(`%%${key}%%`, values[key]);
   });

   let staticAttributs = {
      FORM_DATE: getFormattedDateTime(),
      FORM_SOURCE: typeof window !== "undefined" ? window.location.href : "Unable to fetch URL.",
   };

   Object.keys(staticAttributs).forEach((key) => {
      text = text.replace(`%%${key}%%`, staticAttributs[key]);
   });

   return text;
};
