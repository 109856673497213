export const isContent = (content) => {
   return content && content[0].content;
};

export const useEditor = () => {
   if (typeof window !== "undefined") {
      if (typeof window.storyblok !== "undefined") return window.storyblok.inEditor;
      else return false;
   } else return false;
};
