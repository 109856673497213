import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponent, RenderComponents } from "../../../hooks/components";
import FluidImage from "../../atoms/images/fluidImage";

const HeroFrontpage = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <div className="relative">
            <div className="max-w-7xl mx-auto mt-10">
               <div className="relative shadow-xl rounded-tr-2xl rounded-bl-2xl sm:rounded-tr-5xl sm:rounded-bl-5xl overflow-hidden">
                  <div className="absolute inset-0">
                     {blok.image && blok.image.filename && <FluidImage blok={blok.image} className="h-full w-full object-cover" />}
                     {console.log(blok)}
                     {blok.overlay_color && <div className="absolute inset-0" style={{ mixBlendMode: "multiply", backgroundColor: blok.overlay_color.color }} />}
                  </div>
                  <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                     <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                        <span className="block text-white uppercase">{blok.title}</span>
                     </h1>

                     {blok.introduction && (
                        <div className="leading-relaxed mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                           <RenderComponents bloks={blok.introduction.content} />
                        </div>
                     )}

                     <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                        <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                           {blok.buttons.map((button, index) => (
                              <RenderComponent blok={button} key={`heroFront-${index}`} extraClass={"w-full sm:w-full"} />
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </SbEditable>
   );
};

export default HeroFrontpage;
