import React from "react";
import SbEditable from "storyblok-react";
import { RenderComponents } from "../../../hooks/components";
import FluidImage from "../../atoms/images/fluidImage";

const SectionGallery = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <section className="py-12 md:py-20 -px-4 md:-px:6 mt-0" data-width="full-width">
            <div className="container px-4 mx-auto">
               <div className="max-w-lg mx-auto mb-12 text-center">
                  {blok.surtitle && <span className="inline-block py-1 px-3 text-xs font-semibold  bg-orange-100 bg-opacity-25  text-orange-400 rounded-xl">{blok.surtitle}</span>}
                  <h2 className="md:text-4xl mt-2 mb-4 text-2xl">{blok.title}</h2>
                  {blok.description.content && blok.description.content.length > 0 && (
                     <div className="text-blueGray-500 leading-relaxed mx-auto space-y-4">
                        <RenderComponents bloks={blok.description.content} />
                     </div>
                  )}
               </div>
               <div className="flex flex-wrap lg:flex-nowrap lg:items-center max-w-lg lg:max-w-full mx-auto">
                  <div className="w-full lg:w-1/4 p-3">{blok.images[0] && <FluidImage blok={blok.images[0]} className="rounded-xl object-cover mx-auto" />}</div>
                  <div className="w-full lg:w-2/4 flex flex-col">
                     <div className="flex items-end">
                        <div className="w-2/3 p-3">{blok.images[1] && <FluidImage blok={blok.images[1]} className="rounded-xl object-cover " />}</div>
                        <div className="w-1/3 p-3">{blok.images[2] && <FluidImage blok={blok.images[2]} className="h-48 lg:h-32 rounded-xl object-cover" />}</div>
                     </div>
                     <div className="flex items-start">
                        <div className="w-1/3 p-3">{blok.images[3] && <FluidImage blok={blok.images[3]} className="h-48 lg:h-32 rounded-xl object-cover" />}</div>
                        <div className="w-2/3 p-3">{blok.images[4] && <FluidImage blok={blok.images[4]} className="rounded-xl object-cover" />}</div>
                     </div>
                  </div>
                  <div className="w-full lg:w-1/4 p-3">{blok.images[5] && <FluidImage blok={blok.images[5]} className="rounded-xl object-cover mx-auto" />}</div>
               </div>
            </div>
         </section>
      </SbEditable>
   );
};

export default SectionGallery;
