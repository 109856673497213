import React from "react";
import Wysiwyg from "./wysiwyg";
import BlockImage from "../images/blockImage";

const Paragraph = ({ blok, extraClasses = "" }) => {
   if (blok.content && blok.content[0].type === "image") {
      return <BlockImage blok={blok.content[0]} />;
   } else {
      return blok.content ? (
         <p className={extraClasses}>
            {blok.content.map((text, index) => (
               <Wysiwyg text={text} key={`text-element-${index}`} />
            ))}
         </p>
      ) : (
         <p>&nbsp;</p>
      );
   }
};

export default Paragraph;
