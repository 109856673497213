import React, { useRef } from "react";
import SbEditable from "storyblok-react";
import { RenderComponents } from "../../../hooks/components";
import { getInputWidth } from "../helpers/utils";

const Checkbox = (props) => {
   const checkboxRef = useRef(null);

   const handleKeyPress = (e) => {
      if (e.key === "Enter") checkboxRef.current.click();
      else return true;
   };

   return (
      <SbEditable content={props.blok}>
         <div className={`input-group relative sm:col-span-2 ${getInputWidth(props.blok)} `}>
            <label className="flex items-center space-x-2">
               <input type="checkbox" name={props.blok.name} id={props.blok.id} value={props.blok.name} className={`form-checkbox rounded-none hover:shadow-outline-orange focus:shadow-outline-orange transition duration-150 ease-in text-orange-200 focus:border-orange-200 hover:border-orange-200 ${props.hasError && "shadow-outline-red hover:shadow-outline-red focus:shadow-outline-red"}`} onChange={props.handleChange} onKeyPress={(e) => handleKeyPress(e)} checked={!!props.value} required={props.blok.required} ref={checkboxRef} />

               <span className={`block -mb-1 ${props.hasError && "text-red-500"}`}>
                  <RenderComponents bloks={props.blok.label.content} />
               </span>
            </label>
         </div>
      </SbEditable>
   );
};

export default Checkbox;
