import React from "react";
import SbEditable from "storyblok-react";

const HeroPage = ({ blok }) => {
   const getWrapperWidth = (width) => {
      switch (width) {
         case "medium":
            return "max-w-5xl";
         case "small":
            return "max-w-prose";
         default:
            return "max-w-7xl";
      }
   };

   return (
      <SbEditable content={blok}>
         <div className="relative">
            <div className={`${getWrapperWidth(blok.wrapper_width)} mx-auto mt-10`}>
               <h1 className="text-gray-900 text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-5xl">{blok.title}</h1>
            </div>
         </div>
      </SbEditable>
   );
};

export default HeroPage;
