import React from "react";
import SbEditable from "storyblok-react";

const SectionFeatures = ({ blok }) => {
   return (
      <SbEditable content={blok}>
         <section className="pt-16 pb-20 bg-blueGray-50 -mx-4 md:-mx-6 mt-0" data-width="full-width">
            <div className="container px-4 mx-auto">
               <div className="flex flex-wrap items-center mb-12">
                  <div className="w-full lg:w-1/2 mb-6 lg:mb-0">
                     <h2 className="text-4xl font-bold" dangerouslySetInnerHTML={{ __html: blok.title }} />
                  </div>
                  <div className="w-full lg:w-1/2">
                     <p className="lg:pl-16 text-blueGray-400 leading-loose">{blok.description}</p>
                  </div>
               </div>
               <div className="flex flex-wrap -mx-3 -mb-6 items-center">
                  {blok.cards.map((card) => (
                     <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6" key={card._uid}>
                        <div className="pt-8 px-6 pb-6 bg-white text-center rounded shadow h-full">
                           <div className="w-10 h-10 mx-auto mb-4">
                              <img src={card.icon.filename} alt={card.icon.alt} className="w-full h-full object-contain" />
                           </div>

                           <h3 className="mb-2 font-bold font-sans">{card.title}</h3>
                           <p className="text-sm text-blueGray-400">{card.text}</p>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </section>
      </SbEditable>
   );
};

export default SectionFeatures;
