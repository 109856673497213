import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";

const FluidImage = ({ blok, width = 900, ...props }) => {
   const fluidProps = getFluidGatsbyImage(blok.filename, {
      maxWidth: width,
   });

   return fluidProps ? <Img fluid={fluidProps} {...props} /> : <img src={blok.filename} {...props} alt={blok.alt || ""} />;
};

export default FluidImage;
