import React from "react";
import Paragraph from "./paragraph";

const Blockquote = ({ blok }) => {
   return (
      <blockquote>
         {blok.content.map((quote, index) => (
            <Paragraph blok={quote} key={`blockquote-paragraph-${index}`} />
         ))}
      </blockquote>
   );
};

export default Blockquote;
