import React, { useEffect, useRef, useState, useCallback } from "react";
import Link from "gatsby-link";

const NavItems = (props) => {
   const [expanded, setExpanded] = useState(false);
   const ref = useRef(null);

   const clickListener = useCallback(
      (event) => {
         if (ref?.current && !ref.current.contains(event.target)) {
            setExpanded(false);
         }
      },
      [ref.current]
   );

   useEffect(() => {
      if (typeof window !== "undefined") {
         if (expanded) {
            window.addEventListener("click", clickListener, false);
         } else {
            window.removeEventListener("click", clickListener, false);
         }
      }
   }, [expanded]);

   console.log(props);

   return (
      <li className="relative">
         {props.blok.nav_item_childs && props.blok.nav_item_childs.length > 0 ? (
            <>
               <button
                  className={`flex items-center space-x-1 font-bold text-sm 2md:text-white hover:underline focus:underline transition duration-150 ease-in-out py-1 md:py-0 ${props.location && props.location.pathname.includes(props.blok.link.cached_url) ? "underline" : "no-underline"}`}
                  to={props.blok.link.cached_url === "home" ? "" : props.blok.link.cached_url}
                  onClick={(event) => {
                     event.preventDefault();
                     setExpanded(!expanded);
                  }}
               >
                  <span>{props.blok.name}</span>
                  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.0832 1.57462L11.5418 1.03322C11.4133 0.9047 11.2055 0.9047 11.0769 1.03322L6.125 5.97422L1.17305 1.03322C1.04453 0.9047 0.83672 0.9047 0.708204 1.03322L0.166798 1.57462C0.0382828 1.70314 0.0382828 1.91095 0.166798 2.03946L5.89257 7.76524C6.02109 7.89375 6.2289 7.89375 6.35742 7.76524L12.0832 2.03946C12.2117 1.91095 12.2117 1.70314 12.0832 1.57462Z" fill="#000" />
                  </svg>
               </button>

               <div className={`2md:absolute -ml-4 mt-3 rounded-lg 2md:shadow-lg transform px-2 w-full 2md:w-56 sm:px-0 2md:ml-0 2md:left-1/2 2md:-translate-x-1/2 2md:transition 2md:ease-out 2md:duration-200 z-20 relative bg-white overflow-hidden js-dropdown ${!expanded ? "hidden translate-y-1 opacity-0" : "translate-y-0 opacity-100"}`} aria-expanded="false" ref={ref}>
                  <ul className="px-2 pb-1 2md:rounded-lg 2md:shadow-xs 2md:py-2 2md:p-2 2md:border 2md:border-gray-200">
                     {props.blok.nav_item_childs.map((child) => {
                        return (
                           <li className="relative" key={child._uid}>
                              <Link to={`/${child.link.cached_url}`} className="flex items-start px-3 py-2 space-x-4 text-sm text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100" activeClassName="bg-gray-100" role="menuitem">
                                 {child.name}
                              </Link>
                           </li>
                        );
                     })}
                  </ul>
               </div>
            </>
         ) : props.blok.link.linktype === "story" ? (
            <Link className={`block my-3 2md:my-0 text-md 2md:text-sm 2md:text-white font-bold hover:underline focus:underline transition duration-150 ease-in-out ${props.blok.name === "E-shop" ? "inline-block px-4 py-3 text-sm text-white bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 no-underline font-semibold leading-none border border-orange-500 hover:border-orange-400 2md:text-white hover:no-underline transform hover:scale-105 transition duration-150 ease-in" : ""}`} activeClassName="underline" to={"/" + (props.blok.link.cached_url === "home" ? "" : props.blok.link.cached_url)}>
               {props.blok.name}
            </Link>
         ) : (
            <a className={`block my-3 2md:my-0 text-md 2md:text-sm 2md:text-white font-bold hover:underline focus:underline transition duration-150 ease-in-out ${props.blok.name === "E-shop" ? "inline-block px-4 py-3 text-sm text-white bg-orange-500 hover:bg-orange-400 focus:bg-orange-400 no-underline font-semibold leading-none border border-orange-500 hover:border-orange-400 2md:text-white hover:no-underline transform hover:scale-105 transition duration-150 ease-in" : ""}`} target="_blank" rel="noopener" href={props.blok.link.cached_url}>
               {props.blok.name}
            </a>
         )}
      </li>
   );
};

export default NavItems;
