import React, { useEffect, useState } from "react";

import { validateInput, validateCheckbox } from "./helpers/validation";
import { parseTextByValues } from "./helpers/utils";
import Mandrill from "mandrill-api";
import showdown from "showdown";

/* Import Components */
import Checkbox from "./components/checkbox";
import Radio from "./components/radio";
import Input from "./components/input";
import Textarea from "./components/textarea";
import Select from "./components/select";
// import Button from "./components/button";
import Loader from "./loader";

const Form = ({ blok }) => {
   const [formData, setFormData] = useState({ "your-valid-attribute": "" });
   const [formError, setFormError] = useState({});
   const [loaded, setLoaded] = useState(false);
   const [mandrillState, setMandrillState] = useState("waiting");
   const [displayLabel, setDisplayLabel] = useState(false);

   useEffect(() => {
      defineDefaultValue();
   }, []);

   const handleFormSubmit = (e) => {
      setDisplayLabel(false);

      e.preventDefault();

      let hasError = false;
      let currentError = {};

      // Honeypot
      if (formData["your-valid-attribute"] && formData["your-valid-attribute"].length > 0) {
         hasError = true;
         currentError["your-valid-attribute"] = true;
      }

      blok.inputs.forEach((input) => {
         currentError[input.name] = false;

         if (input.component === "input_text" || input.component === "input_textarea") {
            if (!validateInput(input, formData[input.name])) {
               hasError = true;
               currentError[input.name] = true;
            }
         } else if (input.component === "input_checkbox") {
            if (!validateCheckbox(input, formData[input.name])) {
               hasError = true;
               currentError[input.name] = true;
            }
         }
      });

      setFormError(currentError);

      if (!hasError) {
         // On affiche le loader
         setMandrillState("loading");

         let m = new Mandrill.Mandrill(process.env.GATSBY_MANDRILL_KEY);

         let converter = new showdown.Converter();

         let message = {
            from_email: parseTextByValues(blok.sender_email, formData),
            from_name: parseTextByValues(blok.name_sender, formData),
            to: [
               {
                  email: parseTextByValues(blok.receiver_email, formData),
               },
            ],
            headers: {
               "Reply-To": parseTextByValues(blok.reply_to, formData),
            },
            subject: parseTextByValues(blok.subject, formData),
            html: converter.makeHtml(parseTextByValues(blok.Message, formData)),
         };

         var async = false;

         m.messages.send(
            { message: message, async: async },
            function (result) {
               setMandrillState("success");

               resetForms(e.target);

               setDisplayLabel(true);

               setTimeout(() => {
                  setMandrillState("waiting");
               }, 2000);
            },
            function (e) {
               // Mandrill returns the error as an object with name and message keys
               console.log("A mandrill error occurred: " + e.name + " - " + e.message);

               setMandrillState("error");

               setTimeout(() => {
                  setMandrillState("waiting");
               }, 5000);
               // A mandrill error occurred: Unknown_Subaccount - No subaccount exists with the id 'customer-123'
            }
         );
      }
   };

   const resetForms = (form) => {
      const inputs = form.querySelectorAll("input, textarea, select");

      inputs.forEach((input) => {
         if (input.type === "checkbox") {
            input.checked = false;
         } else if (input.type === "select") {
            input.selectedIndex = 0;
         } else {
            input.value = "";
         }
      });

      defineDefaultValue();
   };

   const defineDefaultValue = () => {
      let defaultState = { "your-valid-attribute": "" };
      let defaultError = { "your-valid-attribute": "" };

      blok.inputs.forEach((input) => {
         if (input.component === "input_radio") {
            defaultState[input.name] = input.default || input.options.split("\n")[0];
         } else {
            defaultState[input.name] = input.default || (input.component === "input_checkbox" ? false : "");
         }

         defaultError[input.name] = false;
      });

      setFormData(defaultState);
      setFormError(defaultError);
      setLoaded(true);
   };

   const handleInput = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      });
   };

   const handleCheckbox = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.checked,
      });
   };

   return (
      loaded && (
         <>
            {displayLabel && (
               <span className="block py-1 px-3 text-sm font-semibold  bg-green-100 bg-opacity-25  text-green-400 rounded-xl mb-3" id="form-submitted">
                  Merci pour votre message. Nos conseillers reprendront prochainement contact avec vous.
               </span>
            )}

            <form onSubmit={(e) => handleFormSubmit(e)} noValidate={true} className="relative grid gap-6 sm:grid-cols-2 md:grid-cols-12">
               <Loader mandrillState={mandrillState} />

               {blok.inputs.map((input) => {
                  switch (input.component) {
                     case "input_radio":
                        return <Radio blok={input} value={formData[input.name]} handleChange={(e) => handleInput(e)} key={input._uid} hasError={formError[input.name]} />;
                     case "input_checkbox":
                        return <Checkbox blok={input} value={formData[input.name]} handleChange={(e) => handleCheckbox(e)} key={input._uid} hasError={formError[input.name]} />;
                     case "input_select":
                        return <Select blok={input} value={formData[input.name]} handleChange={(e) => handleInput(e)} key={input._uid} hasError={formError[input.name]} />;
                     case "input_textarea":
                        return <Textarea blok={input} value={formData[input.name]} handleChange={(e) => handleInput(e)} key={input._uid} hasError={formError[input.name]} />;
                     default:
                        return <Input blok={input} value={formData[input.name]} handleChange={(e) => handleInput(e)} key={input._uid} hasError={formError[input.name]} />;
                  }
               })}
               {/* // TODO : Add dynamic button from components */}
               {/* opacity-0 absolute top-0 left-0 height-none width-none -z-10 */}
               <input type="text" name="your-valid-attribute" className="opacity-0 absolute top-0 left-0 height-none width-none -z-10" tabIndex="-1" autoComplete="off" onChange={(e) => handleInput(e)} value={formData["your-valid-attribute"]} />

               <div className="sm:col-span-2 md:col-span-12">
                  <button className="w-full sm:w-auto px-8 py-2 bg-brown-500 font-bold text-white rounded-none hover:bg-brown-400 focus:bg-brown-400 transition ease-in-out duration-300" type="submit">
                     Envoyer
                  </button>
               </div>
            </form>
         </>
      )
   );
};

export default Form;
