import React from "react";
import { Link } from "gatsby";
import SbEditable from "storyblok-react";
import { getButtonTheme } from "../../../helpers/theme/color";
import { useEditor } from "../../../helpers/content";

// TODO: Add target _blank on all external link

const Button = ({ blok, extraClass, extraClasses, blockBackground }, ...props) => {
   const buttonTheme = getButtonTheme(blok.button_color, blok.button_type, blockBackground);
   console.log(blok);
   const buttonClasses = blok.button_type === "border" ? `w-full sm:w-auto inline-flex items-center justify-center py-1 text-sm border-transparent text-center transition duration-150 ease-in-out border-2 rounded-none no-underline font-bold ${extraClass} ${buttonTheme.renderedClass}` : `w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 text-sm border-transparent text-center transition duration-150 ease-in-out md:px-10 border-2 rounded-none no-underline hover:no-underline focus:no-underline font-bold ${extraClass} ${buttonTheme.renderedClass}`;

   return useEditor() ? (
      <SbEditable content={blok}>
         <span className={`block text-${blok.button_align}`} data-block="button">
            <span className={buttonClasses} data-block="button">
               {blok.title}
            </span>
         </span>
      </SbEditable>
   ) : blok.link.linktype === "story" ? (
      <SbEditable content={blok}>
         <span className={`block text-${blok.button_align}`} data-block="button">
            <Link to={`/${blok.link.cached_url}`} className={buttonClasses} data-block="button">
               {blok.title}
            </Link>
         </span>
      </SbEditable>
   ) : (
      <SbEditable content={blok}>
         <span className={`block text-${blok.button_align}`} data-block="button">
            <a href={blok.link.url} className={buttonClasses} data-block="button" target="_blank">
               {blok.title}
            </a>
         </span>
      </SbEditable>
   );
};

export default Button;
