import React from "react";
import Paragraph from "./paragraph";

const orderedList = ({ blok }) => {
   let listItem = [];
   blok.content.forEach((item) => {
      item.content.forEach((contentItem) => {
         listItem.push(contentItem);
      });
   });

   return (
      blok.content && (
         <ol>
            {listItem.map((item, i) => {
               return (
                  <li key={`olist-item-${i}`}>
                     <Paragraph blok={item} extraClasses={"m-0"} />
                  </li>
               );
            })}
         </ol>
      )
   );
};

export default orderedList;
