import { theme } from "../../../tailwind.config";

/**
 * Get contrast color.
 *
 * @since      0.0.1
 * @access     private
 *
 * @param {string} hex hex of color.
 *
 *
 * @return {string} String of foreground color : `white` or `black`.
 *
 */
const getContrast = (hex) => {
   let r = parseInt(hex.substr(1, 2), 16),
      g = parseInt(hex.substr(3, 2), 16),
      b = parseInt(hex.substr(5, 2), 16),
      yiq = (r * 299 + g * 587 + b * 114) / 1000;
   return yiq >= 128 ? "black" : "white";
};

/**
 * Get all colors.
 *
 * Get all available colors set in `tailwind.config.js`
 * in themes.extend.colors.primary
 *
 * @since      0.0.1
 * @access     public
 *
 * @param no params needed.
 *
 *
 * @return {array} Array of object for each color.
 *    @return {object} Object of color with background, foreground, and rendered tailwind classes.
 */

const getAllColors = () => {
   let availableColors = [];
   let themeColorsKey = theme.mk.colors.mainKey;
   let themeColors = theme.extend.colors;

   if (themeColorsKey) {
      Object.keys(themeColorsKey).forEach((colorName) => {
         if (colorName !== "mainKey") {
            let hoverColorKey = themeColorsKey[colorName] <= 700 ? themeColorsKey[colorName] + 100 : themeColorsKey[colorName] - 100;

            let tempArray = {
               colorKey: colorName + "-" + themeColorsKey[colorName],
               backgroundColor: `bg-${colorName}-${themeColorsKey[colorName]}`,
               backgroundColorHover: `bg-${colorName}-${hoverColorKey}`,
               foregroundColor: `text-${getContrast(themeColors[colorName][themeColorsKey[colorName]])}`,
               foregroundColorHover: `text-${getContrast(themeColors[colorName][themeColorsKey[colorName]])}`,
            };

            availableColors[colorName] = {
               ...tempArray,
               renderedBlock: `${tempArray.backgroundColor} ${tempArray.foregroundColor}`,
            };
         }
      });
   }

   return availableColors;
};

const getBlockTheme = (backgroundColor) => {
   let relatedColors = getAllColors()[backgroundColor];

   if (relatedColors) return relatedColors;
   else console.log("%cError in color.js%c : Color `" + backgroundColor + "` not finded in available colors.", "color:red;", "");

   return false;
};

const getButtonTheme = (buttonColor, buttonType = "background", blockBackground = null) => {
   let relatedColors;

   if (buttonColor === "" || buttonColor === "primary" || buttonColor === "white" || buttonColor === "transparent") {
      relatedColors = {
         colorKey: "white",
         backgroundColor: `bg-white`,
         backgroundColorHover: `bg-gray-300`,
         foregroundColor: `text-black`,
         foregroundColorHover: `text-black`,
      };
   } else {
      relatedColors = getAllColors()[buttonColor];
   }

   // On défini le type de bouton
   let commonClass = `border-${relatedColors.backgroundColor} hover:border-${relatedColors.backgroundColorHover} focus:border-${relatedColors.backgroundColorHover}`;

   if (buttonType === "fill") {
      relatedColors = {
         ...relatedColors,
         renderedClass: `border-${relatedColors.colorKey} text-${relatedColors.colorKey} hover:bg-${relatedColors.colorKey} focus:bg-${relatedColors.colorKey} ${blockBackground ? `focus:text-${blockBackground} hover:text-${blockBackground}` : `hover:${relatedColors.foregroundColor} focus:${relatedColors.foregroundColor}`} ${commonClass}`,
      };
   } else if (buttonType === "border") {
      relatedColors = {
         ...relatedColors,
         renderedClass: `bg-transparent border-transparent text-${relatedColors.colorKey} hover:underline focus:underline ${commonClass}`,
      };
   } else {
      relatedColors = {
         ...relatedColors,
         renderedClass: `${relatedColors.backgroundColor} hover:${relatedColors.backgroundColorHover} focus:${relatedColors.backgroundColorHover} ${relatedColors.foregroundColor} hover:${relatedColors.foregroundColorHover} focus:${relatedColors.foregroundColorHover} ${commonClass}`,
      };
   }

   if (relatedColors) return relatedColors;
   else console.log("%cError in color.js%c : Color `" + buttonColor + "` not finded in available colors.", "color:red;", "");
};

export { getBlockTheme, getAllColors, getButtonTheme };
