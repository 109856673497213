import React from "react";
import { RenderComponents } from "../hooks/components";

const getTemplate = (template) => {
   switch (template) {
      case "inner_page":
         return "page--inner";
      default:
         return false;
   }
};

const Page = (props) => {
   let template = getTemplate(props.blok.page_template);

   return (
      <>
         <main id="main" className={`${template ? `${template} ` : ""}entry-content overflow-x-hidden px-4`}>
            <RenderComponents bloks={props.blok.body} />
         </main>
      </>
   );
};

export default Page;
