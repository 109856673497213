import React from "react";

const Heading = ({ blok }) => {
   const HeadingComponent = `h${blok.attrs.level}`;

   return blok.content ? (
      <HeadingComponent>
         {blok.content.map((h, key) => {
            return <React.Fragment key={`heading-${key}`}>{h.text}</React.Fragment>;
         })}
      </HeadingComponent>
   ) : (
      <HeadingComponent>&nbsp;</HeadingComponent>
   );
};

export default Heading;
