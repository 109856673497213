import Paragraph from "./atoms/wysiwyg/paragraph";
import UnorderedList from "./atoms/wysiwyg/unorderedList";
import OrderedList from "./atoms/wysiwyg/orderedList";
import Blockquote from "./atoms/wysiwyg/blockquote";
import HorizontalRule from "./atoms/wysiwyg/horizontalRule";
import Page from "./page";
import NavItem from "./nav_item";
import ComponentNotFound from "./component_not_found";
import Navi from "./navi";
import ContentCentered from "./organisms/content/contentCentered";
import ContentTwoColumns from "./organisms/content/contentTwoColumns";
import Blok from "./blok";
import Heading from "./atoms/wysiwyg/heading";
import Button from "./atoms/buttons/button";
import Content from "./organisms/content/content";
import BlockImage from "./atoms/images/blockImage";
import ReusableBlock from "./organisms/content/reusableBlock";
import Form from "../plugins/forms/form";

// BLOCKS
import BlockEcwid from "./organisms/blocks/blockEcwid";

// SECTIONS
import SectionFeatures from "./organisms/sections/sectionFeatures";
import SectionTestimonials from "./organisms/sections/sectionTestimonials";
import SectionContact from "./organisms/sections/sectionContact";
import SectionGallery from "./organisms/sections/sectionGallery";
import HeroStore from "./organisms/heros/heroStore";
import SectionNewsletter from "./organisms/sections/sectionNewsletter";
import HeroFrontpage from "./organisms/heros/heroFrontpage";
import HeroPage from "./organisms/heros/heroPage";

const ComponentList = {
   blok: Blok,
   paragraph: Paragraph,
   heading: Heading,
   bullet_list: UnorderedList,
   ordered_list: OrderedList,
   blockquote: Blockquote,
   horizontal_rule: HorizontalRule,
   page: Page,
   post: Page,
   global_navi: Navi,
   nav_item: NavItem,
   nav_item_with_child: NavItem,
   button: Button,
   richtext: Content,
   content_editor: Content,
   image: BlockImage,
   content: Content,
   content_centered: ContentCentered,
   content_two_columns: ContentTwoColumns,
   reusable_block: ReusableBlock,
   forms: Form,
   bloc_ecwid: BlockEcwid,
   section_features: SectionFeatures,
   section_testimonials: SectionTestimonials,
   section_contact: SectionContact,
   section_gallery: SectionGallery,
   section_newsletter: SectionNewsletter,
   hero_store: HeroStore,
   hero_frontpage: HeroFrontpage,
   hero_page: HeroPage,
};

const Components = (type) => {
   if (typeof ComponentList[type] === "undefined") {
      return ComponentNotFound;
   }
   return ComponentList[type];
};

export default Components;
